import { Component, Vue } from 'vue-property-decorator';
import Cutout from '@/components/CutoutComponent/CutoutComponent.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import {
  ImageSizes,
  ImageSize,
  ImageSizesQuoteThumbnail,
} from '@/common/interfaces/SizeBreakpoints';
import { getComponentProps } from '@/utils/vueUtils';
import { QuoteBlockViewModel } from './QuoteModule.types';
import { getResizeImage } from '@/utils/commonUtils';

const QuoteModuleProps = Vue.extend({
  props: getComponentProps<QuoteBlockViewModel>({
    Quote: 'My experience was more than I could imagine. A real life changer.',
    SubQuote: 'Daryl K Guest May 2019',
    Image: {
      Url: getResizeImage('thumbnail-daryl.jpg'),
      Alt: 'Phone',
    },
  }),
});

@Component({
  components: {
    Cutout,
    ResponsiveImage,
  },
})
export default class QuoteModule extends QuoteModuleProps {
  imageSizesThumbnail: ImageSizes = ImageSizesQuoteThumbnail;

  cutoutSizes: Array<ImageSizes> = [
    {
      def: new ImageSize([68, 68]),
      sizes: {
        xl: new ImageSize([95, 95]),
      },
    },
    {
      def: new ImageSize([68, 68]),
      sizes: {
        xl: new ImageSize([95, 95]),
      },
    },
    {
      def: new ImageSize([68, 68]),
      sizes: {
        xl: new ImageSize([95, 95]),
      },
    },
    {
      def: new ImageSize([64, 48]),
      sizes: {},
    },
  ];
}
